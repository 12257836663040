interface IRAParams {
  pagination?: { page: number; perPage: number };
  sort?: { field: string; order: string };
  filter?: Object[];
  data?: Object;
  id?: string | number;
  ids?: string[] | number[];
  meta?: Object;
}


const parameterSanitizer = (parameters: IRAParams) => {
  return Object.fromEntries( Object.entries(parameters).filter((field) => {
    if (typeof field[1] !== 'object') {
      return field[1];
    } else {
      return Object.keys(field[1]).length;
    }
  }))
}

const filterParser = (parameters: IRAParams) => {
  if (parameters.filter) {
    return { ...parameters, filter: Object.entries(parameters.filter).map(([key, value]) => ({ key, value })) };
  } else {
    return parameters
  }
}


const parameterHelper = (parameters: IRAParams) => {
  return filterParser(parameterSanitizer(parameters))
}

export default parameterHelper