import { DateInput, Edit, SimpleForm, TextInput } from 'react-admin';

export const EventEdit = () => (
  <Edit>
    <SimpleForm>
      <TextInput disabled label="ID" source="id" />
      <DateInput source="startAt" />
      <DateInput source="endAt" />
    </SimpleForm>
  </Edit>
);
