import { BooleanInput, Edit, NumberInput, SimpleForm, TextInput } from 'react-admin';

export const UserEdit = () => (
  <Edit>
    <SimpleForm>
      <TextInput disabled source="id" />
      <NumberInput source="birthYear" />
      <TextInput source="countryCode" />
      <TextInput source="gender" />
      <NumberInput disabled source="moryIndex" />
      <TextInput disabled source="rank" />
      <TextInput source="appVersion" />
      <BooleanInput source="admin" />
    </SimpleForm>
  </Edit>
);
