import {
  IntrospectionInputValue,
  IntrospectionInputTypeRef,
  IntrospectionType,
  IntrospectionObjectType,
  IntrospectionOutputTypeRef,
} from 'graphql';
import { IIntrospectedResource } from './introspected-resource.interface';

interface gqlParams {
  name: string;
  type: string;
}

const findArgType = (input: IntrospectionInputTypeRef): string => {
  if (input.kind === 'NON_NULL') {
    return `${findArgType(input.ofType)}!`;
  } else if (input.kind === 'LIST') {
    return `[${findArgType(input.ofType)}]`;
  } else {
    return input.name;
  }
};

class gqlQueryHelper {
  private readonly _fetchType: string;
  private readonly _resource: IIntrospectedResource;
  private readonly _parameters: gqlParams[];
  constructor(raFetchType: string, resource: IIntrospectedResource) {
    this._resource = resource;
    this._fetchType = raFetchType;
    this._parameters = resource[raFetchType]?.args.map((elem: IntrospectionInputValue) => ({
      name: elem.name,
      type: findArgType(elem.type),
    }));
  }

  GetCountName = () => {
    return 'count' + this._resource.type.name + 's';
  };

  GetQueryName = () => {
    return this._resource[this._fetchType].name;
  };

  GetParameters = () => {
    return this._parameters.map((elem) => `$${elem.name}: ${elem.type}`).join(', ');
  };

  GetQueryParameters = () => {
    return this._parameters.map((elem) => `${elem.name}: $${elem.name}`).join(', ');
  };

  GetField = (target: IntrospectionObjectType, schema: readonly IntrospectionType[]) => {
    const checkField = (fieldName: string, type: IntrospectionOutputTypeRef): string => {
      if (type.kind === 'LIST' || type.kind === 'NON_NULL') {
        return checkField(fieldName, type.ofType);
      } else if (type.kind === 'OBJECT') {
        const subtype = schema.find(
          (resource) => resource.name === type.name && resource.kind === 'OBJECT',
        ) as IntrospectionObjectType;
        if (subtype) {
          return `${fieldName} {${this.GetField(subtype, schema)}}`;
        } else {
          throw new Error('unknown type');
        }
      } else {
        return fieldName;
      }
    };

    return target.fields.map((field) => checkField(field.name, field.type)).join('\n');
  };
}

export default gqlQueryHelper;
