import {
  CognitoIdentityClient,
  GetIdCommand,
  GetCredentialsForIdentityCommand,
} from '@aws-sdk/client-cognito-identity';
import { PutObjectCommand, PutObjectCommandInput, S3Client } from '@aws-sdk/client-s3';
import { useEffect, useState } from 'react';
import {
  Edit,
  SimpleForm,
  TextInput,
  DateInput,
  ArrayInput,
  SimpleFormIterator,
  SelectInput,
  useUpdate,
  useNotify,
  useRedirect,
  useRefresh,
} from 'react-admin';
import { v4 as uuid } from 'uuid';


export const AnnounceEdit = () => {
  const [update, { error }] = useUpdate();
  const [s3Client, sets3Client] = useState<S3Client>();

  const notify = useNotify();
  const redirect = useRedirect();
  const refresh = useRefresh();

  useEffect(() => {
    const identityClient = new CognitoIdentityClient({ region: 'ap-northeast-2' });
    const getIdCommend = new GetIdCommand({ IdentityPoolId: 'ap-northeast-2:379a588b-ec4b-40cf-8a88-678582d5d9c5' });
    identityClient.send(getIdCommend).then(({ IdentityId }) => {
      const getCredentialsCommand = new GetCredentialsForIdentityCommand({
        IdentityId,
      });
      identityClient
        .send(getCredentialsCommand)
        .then(({ Credentials }) => {
          if (Credentials) {
            const { AccessKeyId, SessionToken, SecretKey } = Credentials;
            sets3Client(
              new S3Client({
                region: 'ap-northeast-2',
                credentials: {
                  accessKeyId: AccessKeyId ?? '',
                  sessionToken: SessionToken,
                  secretAccessKey: SecretKey ?? '',
                },
              }),
            );
          }
        })
        .catch((e) => {
          console.log('error', e);
        });
    });
  }, [s3Client]);

  const onSave = async (data: any) => {
    try {
      const imageUploads = data.contents
        .map((content: any, index: number) => {
          console.log(content);
          return {
            ...content,
            index, // Keep track of the original position in the array
            key: content.contentsType === 'IMAGE' ? `public/announce/${uuid()}` : null, // Generate a unique key only for images
          };
        })
        .filter((content: any) => content.key && content.image && content.image.rawFile) // Filter non-image contents
        .map(async (imageContent: any) => {
          // Map to a list of promises for the S3 upload
          if (s3Client) {
            const uploadParam: PutObjectCommandInput = {
              Bucket: 'momoryimagebucket134420-dev',
              Key: imageContent.key,
              Body: imageContent.image.rawFile,
              ContentType: imageContent.image.rawFile.type,
            };
            await s3Client.send(new PutObjectCommand(uploadParam));
            return { ...imageContent, s3Url: `s3://${uploadParam.Bucket}/${imageContent.key}` };
          } else {
            throw Error('No S3Client');
          }
        });

      // Wait for all image upload promises to resolve and construct a map of indexes to S3 URLs.
      const imagesS3Urls = (await Promise.all(imageUploads)).reduce(
        (acc, cur) => ({ ...acc, [cur.index]: cur.s3Url }),
        {},
      );

      // Replace each image content with its corresponding S3 URL.
      data.contents = data.contents.map((e: any, index: number) => {
        delete e['__typename'];
        if (e.contentsType === 'IMAGE' && typeof imagesS3Urls[index] === 'string') {
          e.content = imagesS3Urls[index]; // Apply the S3 URL to the content.
        }
        delete e['image'];
        return e;
      });

      await update('Announce', { data });
      if (error) throw new Error('Failed to update!');
      notify(`Updated!`);
      redirect('/Announce');
      refresh();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Edit>
      <SimpleForm onSubmit={onSave}>
        <TextInput disabled label="ID" source="id" />
        <TextInput source="title" />
        <DateInput source="reservedAt" />
        <ArrayInput source="contents">
          <SimpleFormIterator>
            <TextInput source="content" />
            <TextInput source="link" />
            <SelectInput
              source="contentsType"
              choices={[
                { id: 'TEXT', name: '텍스트' },
                { id: 'IMAGE', name: '이미지' },
                { id: 'BUTTON', name: '버튼' },
              ]}
              defaultValue={'TEXT'}
            />
          </SimpleFormIterator>
        </ArrayInput>
      </SimpleForm>
    </Edit>
  );
};
