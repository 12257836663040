import { Datagrid, DateField, List, NumberField, TextField } from 'react-admin';

export const MoryPassList = () => (
  <List>
    <Datagrid rowClick="show" bulkActionButtons={false}>
      <NumberField source="id" label="seasonIdx" />
      <TextField source="seasonKey" />
      <DateField source="startDate" showDate />
      <DateField source="endDate" showDate />
      <NumberField source="days" />
    </Datagrid>
  </List>
);
