import { DateField, FunctionField, Show, SimpleShowLayout, TextField } from 'react-admin';

export const OpenaiClientShow = () => (
  <Show>
    <SimpleShowLayout>
      <TextField source="id" label="ID" />
      <TextField source="provider" label="Provider" />
      <TextField source="clientName" label="Name" />
      <TextField source="apiKey" label="Api Key" />
      <TextField source="endpoint" label="Endpoint" />
    </SimpleShowLayout>
  </Show>
);
