import { BooleanField, Datagrid, List, NumberField, TextField } from 'react-admin';

export const UserList = () => (
  <List>
    <Datagrid rowClick="show">
      <TextField source="id" />
      <TextField source="birthYear" />
      <TextField source="countryCode" />
      <TextField source="gender" />
      <NumberField source="moryIndex" />
      <TextField source="rank" />
      <TextField source="appVersion" />
      <BooleanField source="admin" />
    </Datagrid>
  </List>
);
