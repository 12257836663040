import {
  ArrayInput,
  DateInput,
  Edit,
  FunctionField,
  ImageField,
  ImageInput,
  NumberInput,
  SelectInput,
  SimpleForm,
  SimpleFormIterator,
  TextField,
  TextInput,
  required,
  useNotify,
  useRedirect,
  useRefresh,
  useUpdate,
} from 'react-admin';
import Grid from '@mui/material/Unstable_Grid2';
import { useEffect, useState } from 'react';
import { PutObjectCommand, PutObjectCommandInput, S3Client } from '@aws-sdk/client-s3';
import {
  CognitoIdentityClient,
  GetCredentialsForIdentityCommand,
  GetIdCommand,
} from '@aws-sdk/client-cognito-identity';

const rewardChoices = [
  { id: 'basicGem', name: '특별한모모리 원석' },
  { id: 'specialGem', name: '지정모모리 원석' },
  { id: 'gemShard', name: '원석조각' },
  { id: 'randomBox_low', name: '랜덤박스(하)' },
  { id: 'randomBox_mid', name: '랜덤박스(중)' },
  { id: 'randomBox_high', name: '랜덤박스(상)' },
];

export const MoryPassEdit = () => {
  const [update, { error }] = useUpdate();
  const notify = useNotify();
  const redirect = useRedirect();
  const refresh = useRefresh();
  const [iconKey, setIconKey] = useState<string>();
  const [imageKey, setImageKey] = useState<string>();
  const [s3Client, sets3Client] = useState<S3Client>();

  useEffect(() => {
    const identityClient = new CognitoIdentityClient({ region: 'ap-northeast-2' });
    const getIdCommend = new GetIdCommand({ IdentityPoolId: 'ap-northeast-2:379a588b-ec4b-40cf-8a88-678582d5d9c5' });
    identityClient.send(getIdCommend).then(({ IdentityId }) => {
      const getCredentialsCommand = new GetCredentialsForIdentityCommand({
        IdentityId,
      });
      identityClient
        .send(getCredentialsCommand)
        .then(({ Credentials }) => {
          if (Credentials) {
            const { AccessKeyId, SessionToken, SecretKey } = Credentials;
            sets3Client(
              new S3Client({
                region: 'ap-northeast-2',
                credentials: {
                  accessKeyId: AccessKeyId ?? '',
                  sessionToken: SessionToken,
                  secretAccessKey: SecretKey ?? '',
                },
              }),
            );
          }
        })
        .catch((e) => {
          console.log('error', e);
        });
    });
  }, []);

  const uploadImage = async (data: any) => {
    if (s3Client) {
      const uploadParam: PutObjectCommandInput = {
        Bucket: 'momoryimagebucket134420-dev',
        Key: `public/morypass/${data.title}`,
        Body: data.rawFile,
        ContentType: data.rawFile.type,
      };
      return await s3Client.send(new PutObjectCommand(uploadParam));
    } else throw Error('No S3Client');
  };

  const onSave = async (data: any) => {
    // S3 upload
    if (data?.image && imageKey) {
      await uploadImage(data.image);
      delete data['image'];
      data['imageKey'] = imageKey;
      console.log('image uploaded');
    }
    if (data?.icon && iconKey) {
      await uploadImage(data.icon);
      delete data['icon'];
      data['iconKey'] = iconKey;
      console.log('icon uploaded');
    }

    // Remove __typename
    data.FreeReward = data.FreeReward.map((e: any) => {
      delete e['__typename'];
      return e;
    });
    data.CitizenReward = data.CitizenReward.map((e: any) => {
      delete e['__typename'];
      return e;
    });
    await update('MoryPass', { data });
    if (error) throw new Error('Failed to update!');
    notify(`Updated!`);
    redirect('/MoryPass');
    refresh();
  };

  return (
    <Edit>
      <SimpleForm onSubmit={onSave}>
        <TextInput source="seasonKey" validate={required()} />
        <Grid container spacing={1} direction="row">
          <Grid>
            <ImageInput source="image" onChange={(data) => setImageKey(data.name)} accept="image/*">
              <ImageField source="src" />
            </ImageInput>
            <label>Image Key: </label>
            <FunctionField
              render={() => {
                if (imageKey) return <label>{imageKey}</label>;
                else return <TextField source="imageKey" />;
              }}
            />
          </Grid>
          <Grid>
            <ImageInput source="icon" onChange={(data) => setIconKey(data.name)} accept="image/*">
              <ImageField source="src" />
            </ImageInput>
            <label>Icon Key: </label>
            <FunctionField
              render={() => {
                if (iconKey) return <label>{iconKey}</label>;
                else return <TextField source="iconKey" />;
              }}
            />
          </Grid>
        </Grid>
        <Grid container spacing={1} direction="row">
          <Grid>
            <DateInput source="startDate" validate={required()} />
          </Grid>
          <Grid>
            <DateInput source="endDate" validate={required()} />
          </Grid>
        </Grid>
        <Grid container spacing={1} direction="row">
          <Grid>
            <ArrayInput source="FreeReward" validate={required()}>
              <SimpleFormIterator inline getItemLabel={(index) => `Day ${index + 1}`}>
                <SelectInput source="reward" choices={rewardChoices} validate={required()} defaultValue={'basicGem'} />
                <NumberInput source="count" validate={required()} defaultValue={1} sx={{ width: 75 }} />
              </SimpleFormIterator>
            </ArrayInput>
          </Grid>
          <Grid>
            <ArrayInput source="CitizenReward" validate={required()}>
              <SimpleFormIterator inline getItemLabel={(index) => `Day ${index + 1}`}>
                <SelectInput source="reward" choices={rewardChoices} validate={required()} defaultValue={'basicGem'} />
                <NumberInput source="count" validate={required()} defaultValue={1} sx={{ width: 75 }} />
              </SimpleFormIterator>
            </ArrayInput>
          </Grid>
        </Grid>
      </SimpleForm>
    </Edit>
  );
};
