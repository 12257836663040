import { Datagrid, DateField, List, TextField } from 'react-admin';

export const ReportList = () => (
  <List>
    <Datagrid rowClick="show">
      <DateField source="createdAt" />
      <TextField source="targetImageKey" />
      <TextField
        source="targetContent"
        sx={{
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          display: '-webkit-box',
          WebkitLineClamp: '2',
          WebkitBoxOrient: 'vertical',
        }}
      />
      <TextField source="type" label="Report Type" />
      <TextField source="targetType" />
      <TextField source="status" />
      <DateField source="updatedAt" />
      <TextField source="receivedBy" label="Assigned to" />
    </Datagrid>
  </List>
);
