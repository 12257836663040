import React from 'react';
import Plot from 'react-plotly.js';
import { Card, CardContent } from '@mui/material';

interface StackedBarPlotProps {
    xData: string[];
    yData: { name: string, y: number[] }[];
    title: string;
}

const StackedBarPlot: React.FC<StackedBarPlotProps> = ({ xData, yData, title }) => {
    const traces = yData.map(trace => ({
        x: xData,
        y: trace.y,
        name: trace.name,
        type: 'bar' as const,
    }));

    return (
        <Card>
            <CardContent>
                <Plot
                    data={traces}
                    layout={{
                        barmode: 'stack',
                        width: 600,
                        height: 400,
                        title: title
                    }}
                />
            </CardContent>
        </Card>
    );
};

export default StackedBarPlot;