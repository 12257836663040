import { ApolloClient, InMemoryCache, createHttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';

const uriEndpointList: { [key: string]: string } = {
  local: 'http://localhost:3010/graphql',
  prod: 'https://prod.avocado.land/graphql',
};
const environment = process.env.REACT_APP_ENVIRONMENT || 'dev';
const uriEndpoint = uriEndpointList?.[environment] || 'https://dev.avocado.land/graphql';

type Tokens = {
  accessToken?: string;
  refreshToken?: string;
};

export const loginWithFirebaseToken = async (idToken: string): Promise<Tokens> => {
  return fetch(uriEndpoint, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      query: 'query($firebaseToken:String!){login(firebaseToken:$firebaseToken){accessToken refreshToken}}',
      variables: {
        firebaseToken: idToken,
      },
    }),
  })
    .then((res) => res.json())
    .then((res) => {
      return {
        accessToken: res?.data?.login?.accessToken,
        refreshToken: res?.data?.login?.refreshToken,
      };
    });
};

export const loginWithRefreshToken = async (refreshToken: string) => {
  return fetch(uriEndpoint, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      authorization: `Bearer ${refreshToken}`,
    },
    body: JSON.stringify({
      query: 'query {loginByRefreshToken {accessToken refreshToken}}',
    }),
  })
    .then((res) => res.json())
    .then((res) => {
      console.log(res);
      return {
        accessToken: res?.data?.loginByRefreshToken?.accessToken,
      };
    });
};

export const apolloClient = () => {
  const httpLink = createHttpLink({
    uri: uriEndpoint,
  });

  const authLink = setContext((_, { headers }) => {
    const accessToken = localStorage.getItem('accessToken');
    const refreshToken = localStorage.getItem('refreshToken');
    const lastLogin = localStorage.getItem('lastLogin');

    // Token 없을 경우
    if (!accessToken || !refreshToken) {
      console.log('Token Missing!');
      return {
        headers: {
          ...headers,
        },
      };
    }

    // 발급 받은 지 50분 지났을 경우
    if (lastLogin && Date.now() - Number(lastLogin) >= 1000 * 60 * 5) {
      loginWithRefreshToken(refreshToken || '')
        .then((res) => {
          localStorage.setItem('accessToken', res.accessToken);
          localStorage.setItem('lastLogin', Date.now().toString());
          return {
            headers: {
              ...headers,
              authorization: `Bearer ${res.accessToken.toString()}`,
            },
          };
        })
        .catch(() => {
          throw new Error('Error occured while refreshing access token.');
        });
    }

    return {
      headers: {
        ...headers,
        authorization: `Bearer ${accessToken}`,
      },
    };
  });

  return new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache(),
    name: 'react-web-client',
    version: '1.3',
    queryDeduplication: false,
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'cache-and-network',
      },
    },
  });
};
