import { IntrospectionResult } from 'ra-data-graphql';
import { gql, ApolloQueryResult } from '@apollo/client';
import gqlQueryHelper from './gql-query.helper';
import parameterHelper from './parameter.helper';
import GemQueryBuilder from '../../gem/gem-query-builder';

export const commonGQLQueryBuilder = (introspectionResult: IntrospectionResult) => {
  return (raFetchType: string, resourceName: string, params: any) => {
    if (resourceName === 'Gem') {
      return GemQueryBuilder(raFetchType, params);
    }

    const resource = introspectionResult.resources.find((resource) => resource.type.name === resourceName);

    if (resource !== undefined) {
      const h = new gqlQueryHelper(raFetchType === 'DELETE_MANY' ? 'DELETE' : raFetchType, resource);
      switch (raFetchType) {
        case 'GET_ONE': {
          return {
            query: gql`query ${h.GetQueryName()}(${h.GetParameters()}) {
                  ${h.GetQueryName()}(${h.GetQueryParameters()}) {
                    ${h.GetField(resource.type, introspectionResult.types)}
                    }
                  }`,
            variables: parameterHelper(params),
            parseResponse: (response: ApolloQueryResult<any>) => ({
              data: response.data[h.GetQueryName()],
            }),
          };
        }

        case 'GET_MANY': {
          return {
            query: gql`query ${h.GetQueryName()}(${h.GetParameters()}) {
                  ${h.GetQueryName()}(${h.GetQueryParameters()}) {
                      ${h.GetField(resource.type, introspectionResult.types)}
                    }
                    ${h.GetCountName()}
                  }`,
            variables: parameterHelper(params),
            parseResponse: (response: ApolloQueryResult<any>) => ({
              data: response.data[h.GetQueryName()],
              total: response.data[h.GetCountName()],
            }),
          };
        }

        case 'GET_LIST': {
          return {
            query: gql`query ${h.GetQueryName()}(${h.GetParameters()}) {\n${h.GetQueryName()}(${h.GetQueryParameters()}) {\n${h.GetField(resource.type, introspectionResult.types)}} \n ${h.GetCountName()}\n}`,
            variables: parameterHelper(params),
            parseResponse: (response: ApolloQueryResult<any>) => ({
              data: response.data[h.GetQueryName()],
              total: response.data[h.GetCountName()],
            }),
          };
        }

        case 'GET_MANY_REFERENCE': {
          if (resourceName === 'Comment') {
            return {
              query: gql`query ${h.GetQueryName()}(${h.GetParameters()}) {
                    ${h.GetQueryName()}(${h.GetQueryParameters()}) {
                        ${h.GetField(resource.type, introspectionResult.types)}
                        subcomment {
                          id
                          userId
                          animal
                          content
                          isReported
                          createdAt
                          updatedAt
                        }
                      }
                      ${h.GetCountName()}(filter: $filter)
                    }`,
              variables: parameterHelper(params),
              parseResponse: (response: ApolloQueryResult<any>) => ({
                data: response.data[h.GetQueryName()],
                total: response.data[h.GetCountName()],
              }),
            };
          }
          return {
            query: gql`query ${h.GetQueryName()}(${h.GetParameters()}) {
                  ${h.GetQueryName()}(${h.GetQueryParameters()}) {
                      ${h.GetField(resource.type, introspectionResult.types)}
                    }
                    ${h.GetCountName()}(filter: $filter)
                  }`,
            variables: parameterHelper(params),
            parseResponse: (response: ApolloQueryResult<any>) => ({
              data: response.data[h.GetQueryName()],
              total: response.data[h.GetCountName()],
            }),
          };
        }

        case 'UPDATE': {
          return {
            query: gql`mutation ${h.GetQueryName()}(${h.GetParameters()}) {
                ${h.GetQueryName()}(${h.GetQueryParameters()}) {
                  ${h.GetField(resource.type, introspectionResult.types)}
                }
              }`,
            variables: parameterHelper(params).data,
            parseResponse: (response: ApolloQueryResult<any>) => ({ data: response.data[h.GetQueryName()] }),
          };
        }

        case 'CREATE': {
          return {
            query: gql`mutation ${h.GetQueryName()}(${h.GetParameters()}) {
                ${h.GetQueryName()}(${h.GetQueryParameters()}) {
                  ${h.GetField(resource.type, introspectionResult.types)}
                }
              }`,
            variables: parameterHelper(params).data,
            parseResponse: (response: ApolloQueryResult<any>) => ({ data: response.data[h.GetQueryName()] }),
          };
        }

        case 'DELETE': {
          if (resourceName === 'Mory' || resourceName === 'Comment') {
            return {
              query: gql`mutation ${h.GetQueryName()}(${h.GetParameters()}) {
                  ${h.GetQueryName()}(${h.GetQueryParameters()}) {
                    ${h.GetField(resource.type, introspectionResult.types)}
                  }
                }`,
              variables: params.meta,
              parseResponse: (response: ApolloQueryResult<any>) => ({ data: response.data[h.GetQueryName()] }),
            };
          } else {
            return {
              query: gql`mutation ${h.GetQueryName()}(${h.GetParameters()}) {
                  ${h.GetQueryName()}(${h.GetQueryParameters()}) {
                    ${h.GetField(resource.type, introspectionResult.types)}
                  }
                }`,
              variables: parameterHelper(params),
              parseResponse: (response: ApolloQueryResult<any>) => ({ data: response.data[h.GetQueryName()] }),
            };
          }
        }

        case 'DELETE_MANY': {
          return {
            query: gql`mutation ${h.GetQueryName()}(${h.GetParameters()}) {
                ${h.GetQueryName()}(${h.GetQueryParameters()}) {
                  ${h.GetField(resource.type, introspectionResult.types)}
                }
              }`,
            variables: parameterHelper(params),
            parseResponse: (response: ApolloQueryResult<any>) => ({ data: response.data[h.GetQueryName()] }),
          };
        }

        default: {
          throw new Error('unknown queryType');
        }
      }
    } else {
      throw new Error('cannot find type');
    }
  };
};
