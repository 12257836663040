import React from 'react';
import { ChipField } from 'react-admin';

const emojiList: { [keys: string]: string } = {
  angry: '🤬',
  annoyed: '😠',
  calm: '😌',
  excited: '😎',
  fun: '🥳',
  gloomy: '🌧️',
  happy: '🥰',
  jealous: '😤',
  lethargic: '🙁',
  love: '❤️‍🔥',
  nervous: '😬',
  proud: '👍',
  regret: '😞',
  sad: '😥',
  scared: '👻',
  surprised: '😱',
};

const colorList: { [keys: string]: string } = {
  angry: '#f50057',
  annoyed: '#015f92',
  calm: '#7cb342',
  excited: '#d81b60',
  fun: '#ff5722',
  gloomy: '#b2a4a6',
  happy: '#ffeb3b',
  jealous: '#f44336',
  lethargic: '#00acc1',
  love: '#ffc107',
  nervous: '#009688',
  proud: '#8e24aa',
  regret: '#2196f3',
  sad: '#29b6f6',
  scared: '#512da8',
  surprised: '#a76b6b',
};

type EmotionTagProps = {
  emotion: string;
  degree?: number;
};

export const EmotionTag = React.memo(({ emotion, degree }: EmotionTagProps) => {
  let emotionWithEmoji = `${Object.keys(emojiList).includes(emotion) ? emojiList[emotion] : ''} ${emotion}`;
  if (degree !== undefined && degree !== null) emotionWithEmoji += ` ${degree}`;
  const emotionColor = Object.keys(colorList).includes(emotion) ? colorList[emotion] : '#bdbdbd';
  return (
    <ChipField
      sx={{ border: `1px solid ${emotionColor}`, backgroundColor: `${emotionColor}26` }}
      source="emotion"
      record={{ emotion: emotionWithEmoji }}
    />
  );
});
