import { gql, ApolloQueryResult } from '@apollo/client';

const GemQueryBuilder = (queryType: string, parameters?: any) => {
  switch (queryType) {
    case 'GET_ONE': {
      return {
        query: gql`
          query UserGem($userId: String!, $id: Int!) {
            UserGem(userId: $userId, id: $id) {
              count
              gemType {
                id
                marble
                shape
                name
              }
            }
          }
        `,
        variables: { id: parameters.id, userId: parameters?.meta?.userId },
        parseResponse: (response: ApolloQueryResult<any>) => {
          const rawData = response.data?.UserGem
          return { data: {id: rawData?.gemType.gemTypeId, ...rawData} }
        },
      };
    }

    case 'GET_MANY': {
      throw new Error ('not implemented')
    }

    case 'GET_LIST': {
      const { filter, pagination, sort, meta } = parameters;
      const parsedFilter = Object.entries(filter).map(([key, value]) => ({ key, value }))
      if (!Object.entries(filter).find((elem) => (elem[0] === 'userId'))) {
        parsedFilter.push({key: 'userId', value: 'dummy'})
      }
      const params = { filter: parsedFilter, pagination, sort, meta }
      return {
        query: gql`
          query AllUserGems($pagination: AdminPaginationInput, $sort: AdminSortInput, $filter: [AdminFilterInput!], $ids: [Int!]) {
          allUserGems(pagination: $pagination, sort: $sort, filter: $filter, ids: $ids) {
            gemType {
              id
              marble
              shape
              name
            }
            count
          }
          countUserGems(filter: $filter)
        }
        `,
        variables: params,
        parseResponse: (response: ApolloQueryResult<any>) => {
          const parsedData = response.data?.allUserGems?.map((elem: any) => {
            const gem = {
              id: elem?.gemType?.id,
              userId: filter?.userId,
              count: elem?.count,
              gemType: elem?.gemType
            };
            return gem
          })
          const res = {
            data: parsedData,
            total: response.data?.countUserGems,
          };
          return res;
        },
      };
    }

    case 'GET_MANY_REFERENCE': {
      throw new Error('not implemented');
    }

    case 'UPDATE': {
      const { data, previousData, meta } = parameters;
      const queryInput = {
        input: {
          count: (data?.count - previousData?.count),
          eventType: 'admin-page',
          userId: meta?.userId,
          gemTypePair: { marble: data?.gemType?.marble, shape: data?.gemType?.shape },
        },
      };
      if (data?.count > previousData?.count) {
        return {
          query: gql`
            mutation Mutation($input: ChangeMoryGemInput!) {
              adminIncreaseGem(input: $input) {
                gemType {
                  id
                  marble
                  shape
                  name
                }
                count
              }
            }
          `,
          variables: queryInput,
          parseResponse: (response: ApolloQueryResult<any>) => {
            const rawData = response.data?.UserGem;
            return { data: { id: rawData?.gemType.gemTypeId, ...rawData } };
          },
        };
      } else if (data?.count < previousData?.count) {
        return {
          query: gql`
            mutation Mutation($input: ChangeMoryGemInput!) {
              adminDecreaseGem(input: $input) {
                gemType {
                  id
                  marble
                  shape
                  name
                }
                count
              }
            }
          `,
          variables: queryInput,
          parseResponse: (response: ApolloQueryResult<any>) => {
            const rawData = response.data?.UserGem;
            return { data: { id: rawData?.gemType.gemTypeId, ...rawData } };
          },
        };
      } else {
        throw new Error('same count')
      }
    }

    case 'CREATE': {
      return {
        query: gql`
          mutation Mutation($input: ChangeMoryGemInput!) {
            adminIncreaseGem(input: $input) {
              gemType {
                id
                marble
                shape
                name
              }
              count
            }
          }
        `,
        variables: {input: {eventType: 'admin-page', ...parameters?.data }},
        parseResponse: (response: ApolloQueryResult<any>) => {
          const rawData = response.data?.UserGem;
          return { data: { id: rawData?.gemType.gemTypeId, ...rawData } };
        },
      };
    }

    case 'DELETE': {
      throw new Error('not implemented');
    }

    case 'DELETE_MANY': {
      throw new Error('not implemented');
    }

    default: {
      throw new Error('unknown queryType');
    }
  }
};

export default GemQueryBuilder;
