import {
  CognitoIdentityClient,
  GetCredentialsForIdentityCommand,
  GetIdCommand,
} from '@aws-sdk/client-cognito-identity';
import { S3Client } from '@aws-sdk/client-s3';

let AvocadoS3Client: S3Client;

export async function createS3Client() {
  const identityClient = new CognitoIdentityClient({ region: 'ap-northeast-2' });
  const getIdCommand = new GetIdCommand({ IdentityPoolId: 'ap-northeast-2:379a588b-ec4b-40cf-8a88-678582d5d9c5' });
  const { IdentityId } = await identityClient.send(getIdCommand);
  const getCredentialsCommand = new GetCredentialsForIdentityCommand({ IdentityId });
  const { Credentials } = await identityClient.send(getCredentialsCommand);
  AvocadoS3Client = new S3Client({
    region: 'ap-northeast-2',
    credentials: {
      accessKeyId: Credentials?.AccessKeyId ?? '',
      sessionToken: Credentials?.SessionToken,
      secretAccessKey: Credentials?.SecretKey ?? '',
    },
  });
}

export async function getS3Client() {
  if (!AvocadoS3Client) await createS3Client();
  return AvocadoS3Client;
}
