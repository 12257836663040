import { DateInput, Edit, ReferenceInput, SimpleForm, TextInput } from 'react-admin';

export const ReportEdit = () => (
  <Edit>
    <SimpleForm>
      <ReferenceInput source="userId" reference="User" />
      <TextInput source="id" />
      <TextInput source="type" />
      <DateInput source="createdAt" />
      <TextInput source="receivedAt" />
      <TextInput source="receivedBy" />
      <TextInput source="resolvedAt" />
      <TextInput source="resolvedBy" />
      <TextInput source="targetType" />
      {/* <ReferenceInput source="targetId" reference="targets" />
      <ReferenceInput source="targetSubId" reference="targetSubs" /> */}
      <ReferenceInput source="targetUserId" reference="User" />
      <TextInput source="targetContent" />
      <TextInput source="targetImageKey" />
      <TextInput source="status" />
      <TextInput source="reportResult" />
      <TextInput source="commentFromUser" />
      <TextInput source="commentFromAdmin" />
      <DateInput source="updatedAt" />
    </SimpleForm>
  </Edit>
);
