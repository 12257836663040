import { loginWithFirebaseToken } from '../data-provider';
import firebaseConfig from '../firebase.config';

type loginInfo = { username: string; password: string };

export const AuthProvider = {
  login: (info: loginInfo) => {
    const firebaseRequest = new Request(
      `https://identitytoolkit.googleapis.com/v1/accounts:signInWithPassword?key=${firebaseConfig.apiKey}`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email: info.username,
          password: info.password,
          returnSecureToken: true,
        }),
      },
    );
    return fetch(firebaseRequest)
      .then((res) => {
        if (res.status < 200 || res.status >= 300) {
          throw new Error(res.statusText);
        }
        return res.json();
      })
      .then((res) => {
        console.log(res);
        return res?.idToken;
      })
      .then((idToken: string) => {
        return loginWithFirebaseToken(idToken);
      })
      .then((res) => {
        console.log(res);
        console.log('login success', res.accessToken, res.refreshToken);
        localStorage.setItem('accessToken', res.accessToken ?? '');
        localStorage.setItem('refreshToken', res.refreshToken ?? '');
        localStorage.setItem('firstLogin', Date.now().toString());
        localStorage.setItem('lastLogin', Date.now().toString());
      });
  },
  checkError: (error: any) => {
    const status = error?.status;
    if (status === 401 || status === 403) {
      localStorage.removeItem('accessToken');
      localStorage.removeItem('refreshToken');
      localStorage.removeItem('lastLogin');
      localStorage.removeItem('firstLogin');
      return Promise.reject();
    }
    // other error code (404, 500, etc): no need to log out
    return Promise.resolve();
  },
  getPermissions: () => {
    // Required for the authentication to work
    return Promise.resolve();
  },
  checkAuth: () => {
    const firstLogin = localStorage.getItem('firstLogin');
    const refreshToken = localStorage.getItem('refreshToken');

    // Refresh Token 없거나 첫 번째 로그인 이후 23.5시간 지난 경우
    if (!refreshToken || !firstLogin || Date.now() - Number(firstLogin) >= 1000 * 60 * 60 * 23.5) {
      return Promise.reject();
    }
    return Promise.resolve();
  },
  logout: () => {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('lastLogin');
    localStorage.removeItem('firstLogin');
    return Promise.resolve();
  },
};
