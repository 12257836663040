import {
  ChipField,
  Datagrid,
  DateField,
  FunctionField,
  Labeled,
  NumberField,
  Pagination,
  ReferenceField,
  ReferenceManyField,
  Show,
  TextField,
  WithListContext,
  useGetRecordId,
} from 'react-admin';
import Grid from '@mui/material/Unstable_Grid2';
import { Divider, List, ListItem } from '@mui/material';

export const UserShow = () => {
  const userMoryFilter = {
    userId: useGetRecordId(),
  };

  const userReportFilter = {
    targetId: useGetRecordId(),
  };

  return (
    <Show emptyWhileLoading>
      <List>
        <ListItem>
          <TextField sx={{ fontWeight: 'bold', fontSize: 32 }} label="User Id" source="id" />
        </ListItem>
        <Divider />
        <ListItem>
          <Grid
            lg
            sx={{
              gridArea: 'info',
              display: 'grid',
              gridTemplateColumns: '200px 200px 1fr',
              gap: 1,
              gridTemplateRows: '60px 50px 1fr',
              gridTemplateAreas: `"status join report"
          "morycount country report"
          "age gender report"
          `,
            }}
          >
            <Labeled label="Status" sx={{ gridArea: 'status' }}>
              <FunctionField
                render={(record: any) => {
                  let status = '정상';
                  if (record?.Restraint) {
                    record.Restraint?.forEach((elem: any) => {
                      const now = new Date().getTime();
                      const start = new Date(elem?.createdAt).getTime();
                      const end = new Date(start + elem?.length * 24 * 60 * 60 * 1000).getTime();
                      if (now < end && start < now) {
                        status = `${new Date(end)}까지 접속 정지`;
                      }
                    });
                  }
                  if (status === '정상') return <ChipField source="status" record={{ status }} color="success" />;
                  else return <ChipField source="status" record={{ status }} color="error" />;
                }}
              />
            </Labeled>
            <Labeled label="가입일" sx={{ gridArea: 'join' }}>
              <DateField source="createdAt" showTime />
            </Labeled>
            <Labeled label="Mory Count" sx={{ gridArea: 'morycount' }}>
              <NumberField source="moryIndex" />
            </Labeled>
            <Labeled label="국가" sx={{ gridArea: 'country' }}>
              <NumberField source="countryCode" />
            </Labeled>
            <Labeled label="나이" sx={{ gridArea: 'age' }}>
              <FunctionField
                label="age"
                render={(record: any) => {
                  if (record?.birthYear) {
                    return new Date().getFullYear() - record.birthYear;
                  } else {
                    return 'Unknown';
                  }
                }}
              />
            </Labeled>
            <Labeled label="성별" sx={{ gridArea: 'gender' }}>
              <FunctionField
                label="age"
                render={(record: any) => {
                  if (record?.gender) {
                    return record.gender;
                  } else {
                    return 'Unknown';
                  }
                }}
              />
            </Labeled>
            <Labeled label="신고 내역" sx={{ gridArea: 'report' }}>
              <ReferenceManyField reference="Report" target="targetSubId" filter={userReportFilter}>
                <WithListContext
                  render={({ data }) => {
                    if (!data || data.length === 0) return <div style={{ fontSize: '14px' }}>N/A</div>;
                    return (
                      <div>
                        {data.map((report: any, idx: number) => {
                          return (
                            <div key={report.id}>
                              <ReferenceField
                                reference="Report"
                                link="show"
                                source="reportId"
                                record={{ reportId: report.id }}
                              >
                                <Labeled label={report.createdAt}>
                                  <TextField source="content" record={{ content: report.type }} />
                                </Labeled>
                              </ReferenceField>
                            </div>
                          );
                        })}
                      </div>
                    );
                  }}
                />
              </ReferenceManyField>
            </Labeled>
          </Grid>
        </ListItem>
        <Divider />
        <ListItem>
          <Labeled label="Mory Log" sx={{ width: '100%' }}>
            <ReferenceManyField pagination={<Pagination />} reference="Mory" target="userId" filter={userMoryFilter}>
              <Datagrid rowClick="show">
                <TextField source="createdAt" />
                <TextField source="text" />
                <TextField source="emotion" />
                <TextField source="isPublic" />
              </Datagrid>
            </ReferenceManyField>
          </Labeled>
        </ListItem>
      </List>
    </Show>
  );
};
