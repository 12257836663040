import { DateField, FunctionField, Show, SimpleShowLayout, TextField } from 'react-admin';

export const EventShow = () => (
  <Show>
    <SimpleShowLayout>
      <TextField source="id" label="ID" />
      <DateField source="startAt" label="Starts" />
      <DateField source="endAt" label="Ends" />
      <FunctionField
        label="Duration"
        render={(record: any) =>
          `${(new Date(record.endAt).getTime() - new Date(record.startAt).getTime()) / 1000 / 60 / 60 / 24} days`
        }
      />
      <FunctionField
        label=""
        render={(record: any) =>
          Date.now() < new Date(record.startAt).getTime() || Date.now() > new Date(record.endAt).getTime()
            ? ''
            : 'Current Event'
        }
      />
    </SimpleShowLayout>
  </Show>
);
