import { DateInput, Create, SimpleForm, TextInput } from 'react-admin';

export const EventCreate = () => (
  <Create>
    <SimpleForm>
      <TextInput disabled label="ID" source="id" />
      <DateInput source="startAt" />
      <DateInput source="endAt" />
    </SimpleForm>
  </Create>
);
