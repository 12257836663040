import { Key } from '@mui/icons-material';
import { Card, CardContent, CardMedia } from '@mui/material';
import { Box, Stack } from '@mui/system';
import { Form, SaveButton, TextInput, useLogin, useNotify } from 'react-admin';

const MyLoginPage = () => {
  const login = useLogin();
  const notify = useNotify();

  const handleSubmit = (e: any) => {
    login({ username: e.email, password: e.password }).catch(() => notify('Invalid email or password'));
  };

  return (
    <Box
      sx={{
        display: 'flex',
        height: '100vh',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Card sx={{ width: 400 }}>
        <CardMedia component="img" height={200} sx={{ objectFit: 'contain' }} image="/welcome.png" />
        <CardContent>
          <Form onSubmit={handleSubmit}>
            <Stack>
              <TextInput source="email" label="Email" type="email" />
              <TextInput source="password" label="Password" type="password" />
              <SaveButton label="Login" icon={<Key />} />
            </Stack>
          </Form>
        </CardContent>
      </Card>
    </Box>
  );
};

export default MyLoginPage;
