import { GetObjectCommand, S3Client } from '@aws-sdk/client-s3';
import { getSignedUrl } from '@aws-sdk/s3-request-presigner';
import React, { useEffect, useState } from 'react';
import { useRecordContext } from 'react-admin';
import { getS3Client } from '../lib/S3Client';

type S3ImageProps = {
  source: string;
  height: number;
  width: number;
  prefix?: string;
};

export const S3Image = React.memo(({ source, height, width, prefix }: S3ImageProps) => {
  const record: Record<string, string> = useRecordContext();

  if (!record) return null;

  if (!record[source]) return <div style={{ width: `${width}px`, height: `${height}px` }}>사진이 없습니다.</div>;

  const key = /(\.gif|\.jpg|\.jpeg|\.png)$/i.test(record[source]) ? record[source] : `${record[source]}.png`;

  const imageKey = prefix ? `public/${prefix}/${key}` : `public/${key}`;

  return ImageLoader(imageKey, height, width);
});

const ImageLoader = (imageKey: string, height: number, width: number) => {
  const [url, setUrl] = useState<string>();
  const [client, setClient] = useState<S3Client>();

  useEffect(() => {
    (async function () {
      setClient(await getS3Client());
    })();

    const getObjectCommand = new GetObjectCommand({
      Key: imageKey,
      Bucket: 'momoryimagebucket134420-dev',
    });

    if (client && imageKey) {
      getSignedUrl(client, getObjectCommand, { expiresIn: 3600 }).then((response) => {
        setUrl(response);
      });
    }
  }, [imageKey, client]);

  return <img src={url} title="image" alt="no img" width={width} height={height} />;
};
