import axios from 'axios';

const endpointList: { [key: string]: string } = {
    local: 'http://localhost:8000',
    dev: 'https://mu-dev-database-fetch.azurewebsites.net/api/MuDataFetch',
    prod: '',
  };


// Fetch data from the API
export const fetchData = async (startDate: string, endDate: string, environment: string = 'dev') => {
    try {
        const response = await axios.post(endpointList[environment], {
            startDate,
            endDate
        });
        return response.data;
    } catch (error) {
        console.error("Error fetching data:", error);
        return null;
    }
};