import { Create, SelectInput, SimpleForm, TextInput } from 'react-admin';

export const OpenaiClientCreate = () => (
  <Create redirect='list'>
    <SimpleForm>
      <TextInput source='clientName' name='client_name' label='Name' />
      <SelectInput source='provider' choices={[
        { id: 'openai', name: 'OpenAI' },
        { id: 'azure', name: 'Azure' },
      ]} />
      <TextInput source="apiKey" name='api_key' label='API Key' />
      <TextInput source="endpoint" label='EndPoint' />
    </SimpleForm>
  </Create>
);
