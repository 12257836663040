import { ArrayField, Datagrid, DateField, NumberField, Show, SimpleShowLayout, TextField, useRecordContext } from 'react-admin';

export const AnnounceShow = () => {
  return (
    <Show emptyWhileLoading>
      <SimpleShowLayout>
        <NumberField source="id" label="ID" />
        <DateField source="createdAt" label="공지사항 작성 일시" />
        <TextField source="title" label="제목"/>
        <DateField source="reservedAt" label="예약 발송 일시" />
        <ArrayField source="contents">
          <Datagrid bulkActionButtons={false}>
            <TextField source="content" sx={{ width: 75 }} label="내용" />
            <TextField source="link" sx={{ width: 75 }} label="링크" />
            <TextField source="contentsType" sx={{ width: 75 }} label="타입" />
          </Datagrid>
        </ArrayField>
      </SimpleShowLayout>
    </Show>
  );
};
