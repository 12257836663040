import {
  ArrayField,
  Datagrid,
  DateField,
  Labeled,
  NumberField,
  SelectField,
  Show,
  SimpleShowLayout,
  TextField,
} from 'react-admin';
import Grid from '@mui/material/Unstable_Grid2';
import { S3Image } from '../common/components/S3Image';

const rewardChoices = [
  { id: 'basicGem', name: '특별한모모리 원석' },
  { id: 'specialGem', name: '지정모모리 원석' },
  { id: 'gemShard', name: '원석조각' },
  { id: 'randomBox_low', name: '랜덤박스(하)' },
  { id: 'randomBox_mid', name: '랜덤박스(중)' },
  { id: 'randomBox_high', name: '랜덤박스(상)' },
];

export const MoryPassShow = () => {
  return (
    <Show>
      <SimpleShowLayout>
        <Grid container spacing={1} direction="row">
          <Grid>
            <SimpleShowLayout>
              <Labeled label="Icon">
                <S3Image source="iconKey" height={180} width={180} prefix="morypass" />
              </Labeled>
            </SimpleShowLayout>
          </Grid>
          <Grid>
            <SimpleShowLayout>
              <Labeled label="Image">
                <S3Image source="imageKey" height={180} width={360} prefix="morypass" />
              </Labeled>
            </SimpleShowLayout>
          </Grid>
        </Grid>

        <Grid container spacing={1} direction="row">
          <Grid>
            <SimpleShowLayout>
              <TextField source="id" label="SeasonIdx" />
              <TextField source="seasonKey" />
              <DateField source="startDate" />
              <DateField source="endDate" />
            </SimpleShowLayout>
          </Grid>
          <Grid>
            <SimpleShowLayout>
              <TextField source="imageKey" />
              <TextField source="iconKey" />
            </SimpleShowLayout>
          </Grid>
        </Grid>
        <Grid container spacing={1} direction="row">
          <Grid>
            <SimpleShowLayout>
              <ArrayField source="FreeReward">
                <Datagrid bulkActionButtons={false}>
                  <SelectField source="reward" choices={rewardChoices} />
                  <NumberField source="count" sx={{ width: 75 }} />
                </Datagrid>
              </ArrayField>
            </SimpleShowLayout>
          </Grid>
          <Grid>
            <SimpleShowLayout>
              <ArrayField source="CitizenReward">
                <Datagrid bulkActionButtons={false}>
                  <SelectField source="reward" choices={rewardChoices} />
                  <NumberField source="count" sx={{ width: 75 }} />
                </Datagrid>
              </ArrayField>
            </SimpleShowLayout>
          </Grid>
        </Grid>
      </SimpleShowLayout>
    </Show>
  );
};
