import React from 'react';
import Plot from 'react-plotly.js';
import { Card, CardContent } from '@mui/material';

interface LinePlotProps {
    xData: string[];
    yData: number[];
    title: string;
}

const LinePlot: React.FC<LinePlotProps> = ({ xData, yData, title }) => {
    return (
        <Card>
            <CardContent>
                <Plot
                    data={[
                        {
                            x: xData,
                            y: yData,
                            type: 'scatter',
                            mode: 'lines+markers',
                            marker: { color: 'rgba(75,192,192,1)' },
                        },
                    ]}
                    layout={{ width: 600, height: 400, title: title }}
                />
            </CardContent>
        </Card>
    );
};

export default LinePlot;