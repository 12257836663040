import { Datagrid, DateField, List, TextField } from 'react-admin';

export const AnnounceList = () => (
  <List>
    <Datagrid rowClick="show">
      <DateField source="createdAt" />
      <TextField source="title" />
      <DateField source="reservedAt" />
    </Datagrid>
  </List>
);
