import { BooleanInput, Create, NumberInput, SimpleForm, TextInput } from 'react-admin';

export const UserCreate = () => (
  <Create>
    <SimpleForm>
      <TextInput source="id" />
      <TextInput source="birthYear" />
      <TextInput source="countryCode" />
      <TextInput source="gender" />
      <NumberInput disabled source="moryIndex" />
      <TextInput disabled source="rank" />
      <TextInput source="appVersion" />
      <BooleanInput source="admin" />
    </SimpleForm>
  </Create>
);
