import { useState } from 'react';
import {
  Button,
  ChipField,
  Confirm,
  Datagrid,
  DateField,
  FunctionField,
  Labeled,
  Pagination,
  ReferenceField,
  ReferenceManyField,
  Show,
  TextField,
  useDataProvider,
  useGetIdentity,
  useNotify,
  useRecordContext,
  useRefresh,
  useShowContext,
} from 'react-admin';
import { useNavigate } from 'react-router-dom';
import Grid from '@mui/material/Unstable_Grid2';
import { Card, Divider, List, ListItem } from '@mui/material';
import { S3Image } from '../common/components/S3Image';

export const ReportShow = () => {
  const { data } = useGetIdentity();

  const AssingButton = () => {
    const { Restraint, ...record } = useRecordContext();
    const [showAssignConfirm, setShowAssignConfirm] = useState(false);
    const dataProvider = useDataProvider();
    const notify = useNotify();
    const refresh = useRefresh();

    const handleAssignEdit = () => {
      const updatedRecord = { ...record, receivedBy: data?.fullName, status: 'pending', receivedAt: Date() };

      dataProvider
        .update('Report', { id: record.id, previousData: record, data: updatedRecord })
        .then((data) => {
          notify(`Report has assiginged to `);
          refresh();
        })
        .catch((error) => {
          console.error(error);
          notify('Error: Record update failed', { type: 'warning' });
        });
    };

    const handleAssignConfirm = () => {
      setShowAssignConfirm(true);
    };

    const handleAssignCancel = () => {
      setShowAssignConfirm(false);
    };

    const handleAssignConfirmYes = () => {
      handleAssignEdit();
      setShowAssignConfirm(false);
    };

    return (
      <>
        <Button onClick={handleAssignConfirm} label={record?.receivedBy ? 'Change Assignment' : 'Assign to me'} />
        <Confirm
          title={`Assign report #${record && record.id}`}
          isOpen={showAssignConfirm}
          content="Are you sure you want to assign this report to you?"
          onConfirm={handleAssignConfirmYes}
          onClose={handleAssignCancel}
        />
      </>
    );
  };

  const DismissButton = () => {
    const { Restraint, ...record } = useRecordContext();
    const [showDismissConfirm, setShowDismissConfirm] = useState(false);
    const dataProvider = useDataProvider();
    const notify = useNotify();
    const refresh = useRefresh();

    const handleDismissEdit = () => {
      const updatedRecord = { ...record, resolvedBy: data?.fullName, status: 'resolved', resolvedAt: Date() };

      dataProvider
        .update('Report', { id: record.id, previousData: record, data: updatedRecord })
        .then((data) => {
          notify(`Report ${record.id} has closed.`);
          refresh();
        })
        .catch((error) => {
          console.error(error);
          notify('Error: Record update failed', { type: 'warning' });
        });
    };

    const handleDismissConfirm = () => {
      setShowDismissConfirm(true);
    };

    const handleDismissCancel = () => {
      setShowDismissConfirm(false);
    };

    const handleDismissConfirmYes = () => {
      handleDismissEdit();
      setShowDismissConfirm(false);
    };

    return (
      <>
        <Button onClick={handleDismissConfirm} label="Dismiss" />
        <Confirm
          title={`Dismiss report #${record && record.id}`}
          isOpen={showDismissConfirm}
          content="Are you sure you want to dismiss this report without any action?"
          onConfirm={handleDismissConfirmYes}
          onClose={handleDismissCancel}
        />
      </>
    );
  };

  const BanButton = () => {
    const record = useRecordContext();
    const nav = useNavigate();

    const handleBanPage = () => {
      nav(
        `/ban/${record.id}/${record?.userId}/${record?.targetType}/${encodeURIComponent(
          record.targetSubId,
        )}/${encodeURIComponent(record.targetId)}`,
      );
    };

    return <Button label="Ban User" onClick={handleBanPage} />;
  };

  const Aside = () => {
    return (
      <Card sx={{ width: 250, height: 'fit-content', marginLeft: '1rem' }}>
        <List>
          <ListItem>
            <Labeled label="Status">
              <FunctionField
                render={(record: any) => {
                  if (record.status === 'unresolved')
                    return <ChipField source="status" record={{ status: 'Unresolved' }} color="error" />;
                  else if (record.status === 'pending')
                    return <ChipField source="status" record={{ status: 'Pending' }} color="warning" />;
                  else if (record.status === 'dismissed')
                    return <ChipField source="status" record={{ status: 'Dismissed' }} color="default" />;
                  else if (record.status === 'resolved')
                    return <ChipField source="status" record={{ status: 'Resolved' }} color="success" />;
                }}
              />
            </Labeled>
          </ListItem>
          <ListItem>
            <Labeled label="Assigned To">
              <TextField source="receivedBy" emptyText="N/A" />
            </Labeled>
          </ListItem>
          <ListItem>
            <Labeled label="Assigned At">
              <DateField source="receivedAt" showTime emptyText="N/A" />
            </Labeled>
          </ListItem>
          <Divider />
          <ListItem>
            <AssingButton />
          </ListItem>
          <ListItem>
            <BanButton />
          </ListItem>
          <ListItem>
            <DismissButton />
          </ListItem>
        </List>
      </Card>
    );
  };

  return (
    <Show aside={<Aside />}>
      <List>
        <ListItem>
          <TextField sx={{ fontWeight: 'bold', fontSize: 32 }} label={false} source="id" />
        </ListItem>
        <Divider />
        <ListItem>
          <Grid
            lg
            sx={{
              gridArea: 'info',
              display: 'grid',
              gridTemplateColumns: '200px 200px 1fr',
              gap: 1,
              gridTemplateRows: 'auto',
              gridTemplateAreas: `"createdat updatedat image"
          "text text image"
          "type target image"
          "reporttype reporttype image"
          "contentuserid reportuserid image"
          `,
            }}
          >
            <Labeled label="Created At" sx={{ gridArea: 'createdat' }}>
              <DateField source="createdAt" showTime />
            </Labeled>
            <Labeled label="Updated At" sx={{ gridArea: 'updatedat' }}>
              <DateField source="updatedAt" showTime />
            </Labeled>
            <Labeled label="내용" sx={{ gridArea: 'text' }}>
              <TextField source="targetContent" />
            </Labeled>
            <Labeled label="Type" sx={{ gridArea: 'type' }}>
              <TextField source="targetType" />
            </Labeled>
            <Labeled label="Target" sx={{ gridArea: 'target' }}>
              <FunctionField
                label="Content"
                render={(record: any) => {
                  if (record.targetType === 'mory') {
                    return <ReferenceField source="targetSubId" reference="Mory" link="show" />;
                  } else if (record.targetType === 'user') {
                    return <ReferenceField source="targetUserId" reference="User" link="show" />;
                  } else if (record.targetType === 'comment') {
                    return <ReferenceField source="targetSubId" reference="Comment" />;
                  }
                }}
              />
            </Labeled>
            <Labeled label="신고한 유저" sx={{ gridArea: 'reportuserid', overflow: 'hidden' }}>
              <ReferenceField source="userId" reference="User" link="show" />
            </Labeled>
            <Labeled label="신고당한 유저" sx={{ gridArea: 'contentuserid', overflow: 'hidden' }}>
              <ReferenceField source="targetUserId" reference="User" link="show" />
            </Labeled>
            <Labeled label="신고 이유" sx={{ gridArea: 'reporttype' }}>
              <TextField source="type" />
            </Labeled>
            <Labeled label="사진" sx={{ gridArea: 'image' }}>
              <S3Image source="targetImageKey" width={300} height={300} />
            </Labeled>
          </Grid>
        </ListItem>
        <Divider />
        <ListItem>
          <Labeled label="Report List of User">
            <ReferenceManyField
              pagination={<Pagination />}
              reference="Report"
              target="targetUserId"
              filter={{ userId: useShowContext().record?.targetUserId }}
            >
              <Datagrid>
                <TextField source="id" />
                <TextField source="type" />
              </Datagrid>
            </ReferenceManyField>
          </Labeled>
        </ListItem>
      </List>
    </Show>
  );
};
