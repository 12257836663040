import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import { Box, Card, Divider, Grid, List, ListItem, Stack } from '@mui/material';
import { useState } from 'react';
import {
  Button,
  CreateBase,
  Datagrid,
  EditBase,
  Form,
  Identifier,
  ListBase,
  ListToolbar,
  NumberField,
  NumberInput,
  RaRecord,
  SaveButton,
  SelectInput,
  TextField,
  TextInput,
  Title,
  number,
  required,
  useListController,
} from 'react-admin';

export const GemList = () => {
  const { data } = useListController();
  const userId = data?.at(0)?.userId;
  const GemFilters = [<TextInput label="User ID" source="userId" alwaysOn />];

  const [AsideComponent, SetAsideComponent] = useState<ReactJSXElement>(
    <List>
      <ListItem>
        <div>원석 추가 및 수정</div>
      </ListItem>
      <Divider />
      <ListItem>
        <Box sx={{ textJustify: 'center', textAlign: 'center' }}>원석을 추가하거나 수정할 유저를 검색해주세요.</Box>
      </ListItem>
    </List>,
  );

  const gemEditOnClick = (id: Identifier, resource: string, record: RaRecord): false => {
    SetAsideComponent(GemEdit(resource, record));
    return false;
  };

  const gemAddOnClick = () => {
    if (!userId) alert('올바른 User Id를 입력하세요');
    else SetAsideComponent(GemCreate(userId));
    return;
  };

  return (
    <div>
      <Title title="Gem" />
      <Grid
        sx={{
          display: 'grid',
          gridTemplateColumns: '1fr 300px',
          pt: '8px',
          gap: 1,
          gridTemplateRows: 'auto',
          gridTemplateAreas: `"list add"`,
        }}
      >
        <Card sx={{ gridArea: 'list' }}>
          <List>
            <ListItem>
              <div>유저 검색</div>
            </ListItem>
            <Divider />
            <ListItem>
              <ListBase perPage={50} debounce={1000}>
                <Stack sx={{ width: '100%' }}>
                  <ListToolbar
                    filters={GemFilters}
                    actions={<Button label="Add" sx={{ height: '3rem' }} onClick={gemAddOnClick} />}
                  />
                  <Datagrid rowClick={gemEditOnClick} bulkActionButtons={false}>
                    <NumberField source="id" />
                    <TextField source="gemType.marble" label="Marble" />
                    <TextField source="gemType.shape" label="Shape" />
                    <TextField source="count" />
                  </Datagrid>
                </Stack>
              </ListBase>
            </ListItem>
          </List>
        </Card>
        <Card sx={{ gridArea: 'add' }}>{AsideComponent}</Card>
      </Grid>
    </div>
  );
};

const GemEdit = (resource: string, record: RaRecord) => {
  return (
    <List sx={{ pb: 0 }}>
      <ListItem>원석 수정</ListItem>
      <Divider />
      <ListItem sx={{ p: 0, width: '100%' }}>
        <EditBase
          id={record.id}
          queryOptions={{ meta: { userId: record?.userId } }}
          mutationOptions={{ meta: { userId: record?.userId } }}
          resource={resource}
        >
          <Form>
            <Stack sx={{ width: '300px', p: '16px' }}>
              <NumberInput label="ID" source="gemType.id" disabled />
              <TextInput label="Marble" source="gemType.marble" disabled />
              <TextInput label="Shape" source="gemType.shape" disabled />
              <NumberInput source="count" />
              <SaveButton />
            </Stack>
          </Form>
        </EditBase>
      </ListItem>
    </List>
  );
};

const GemCreate = (userId: string) => {
  return (
    <List>
      <ListItem>원석 추가</ListItem>
      <Divider />
      <ListItem sx={{ p: 0, width: '100%' }}>
        <CreateBase>
          <Form record={{ userId }}>
            <Stack sx={{ width: '300px', p: '16px' }}>
              <TextInput label="userId" source="userId" disabled validate={[required()]} />
              <SelectInput
                label="Marble"
                source="gemTypePair.marble"
                choices={marbleChoice}
                validate={[required(), marbleValidation]}
              />
              <SelectInput
                label="Shape"
                source="gemTypePair.shape"
                choices={shapeChoice}
                validate={[required(), shapeValidation]}
              />
              <NumberInput source="count" validate={[required(), number()]} />
              <SaveButton />
            </Stack>
          </Form>
        </CreateBase>
      </ListItem>
    </List>
  );
};

const marbleValidation = (value: any, allValues: { gemTypePair: { marble: string; shape: string } }) => {
  const { marble, shape } = allValues.gemTypePair;
  if (
    shape !== marble &&
    (marble === 'christmas' ||
      marble === 'ara' ||
      marble === 'baseBall' ||
      marble === 'tennisball' ||
      marble === 'shard')
  ) {
    return `Invalid Gem Pair: Shape should be ${marble}, too.`;
  }
  if (value < 18) {
    return `Invalid Gem Pair: Marble should be 'gold' or 'silver' or 'copper.`;
  }
  return undefined;
};
const shapeValidation = (value: any, allValues: { gemTypePair: { marble: string; shape: string } }) => {
  const { marble, shape } = allValues.gemTypePair;
  if (
    shape !== marble &&
    (shape === 'christmas' || shape === 'ara' || shape === 'baseBall' || shape === 'tennisball' || shape === 'shard')
  ) {
    return `Invalid Gem Pair: Shape should be ${shape}, too.`;
  }
  if (
    ((marble === 'gold' || marble === 'silver' || marble === 'copper') &&
      !(shape === 'typeA' || shape === 'typeB' || shape === 'typeC')) ||
    (!(marble === 'gold' || marble === 'silver' || marble === 'copper') &&
      (shape === 'typeA' || shape === 'typeB' || shape === 'typeC'))
  ) {
    return `Invalid Gem Pair: Shape should be 'typeA' or 'typeB' or 'typeC'.`;
  }
  return undefined;
};

const marbleChoice = [
  { id: 'basic', name: 'basic' },
  { id: 'jumbak', name: 'jumbak' },
  { id: 'zebra', name: 'zebra' },
  { id: 'wave', name: 'wave' },
  { id: 'camou', name: 'camou' },
  { id: 'milkyway', name: 'milkyway' },
  { id: 'iridescent', name: 'iridescent' },
  { id: 'steel', name: 'steel' },
  { id: 'fabric', name: 'fabric' },
  { id: 'glassDeco', name: 'glassDeco' },
  { id: 'steelDeco', name: 'steelDeco' },
  { id: 'tennisball', name: 'tennisball' },
  { id: 'baseball', name: 'baseball' },
  { id: 'gemStone', name: 'gemStone' },
  { id: 'wood', name: 'wood' },
  { id: 'paper', name: 'paper' },
  { id: 'gold', name: 'gold' },
  { id: 'silver', name: 'silver' },
  { id: 'copper', name: 'copper' },
  { id: 'ara', name: 'ara' },
  { id: 'christmas', name: 'christmas' },
  { id: 'shard', name: 'shard' },
];
const shapeChoice = [
  { id: 'ball', name: 'ball' },
  { id: 'baduk', name: 'baduk' },
  { id: 'capsule', name: 'capsule' },
  { id: 'dice', name: 'dice' },
  { id: 'golfball', name: 'golfball' },
  { id: 'halfsphere', name: 'halfsphere' },
  { id: 'hexagon', name: 'hexagon' },
  { id: 'pear', name: 'pear' },
  { id: 'pebble', name: 'pebble' },
  { id: 'pyramid', name: 'pyramid' },
  { id: 'starcandy', name: 'starcandy' },
  { id: 'tennisball', name: 'tennisball' },
  { id: 'baseball', name: 'baseball' },
  { id: 'apple', name: 'apple' },
  { id: 'bear', name: 'bear' },
  { id: 'cat', name: 'cat' },
  { id: 'typeA', name: 'typeA' },
  { id: 'typeB', name: 'typeB' },
  { id: 'typeC', name: 'typeC' },
  { id: 'ara', name: 'ara' },
  { id: 'christmas', name: 'christmas' },
  { id: 'shard', name: 'shard' },
];
