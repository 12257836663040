import { useEffect, useState } from 'react';
import { Admin, CustomRoutes, DataProvider, Resource, combineDataProviders } from 'react-admin';
import buildGraphQLProvider, { Options } from 'ra-data-graphql';
import { apolloClient, commonGQLQueryBuilder } from '../data-provider';
import { EventCreate, EventList, EventEdit, EventShow } from '../streak-event';
import { UserCreate, UserEdit, UserList, UserShow } from '../user';
import { MoryList, MoryShow } from '../mory';
import { ReportEdit, ReportList, ReportShow } from '../report';
import { Ban } from '../ban/ban';
import { Route } from 'react-router-dom';
import { GemList } from '../gem';
import { MoryPassList } from '../morypass';
import { MoryPassEdit } from '../morypass/morypass.edit';
import { MoryPassCreate } from '../morypass/morypass.create';
import { MoryPassShow } from '../morypass/morypass.show';
import { AuthProvider } from '../auth-provider/auth-provider';
import Schema from '../schema.json';
import { IntrospectionSchema } from 'graphql';
import { Analytics, Campaign, Check, Diamond, EmojiEmotions, EmojiEvents, Person, Psychology, Redeem, Report } from '@mui/icons-material';
import MyLoginPage from '../login/LoginPage';
import { MomoryTheme } from '../common/theme/MomoryTheme';
import { AnnounceCreate, AnnounceEdit, AnnounceList, AnnounceShow } from '../announce/';
import { restProviderFactory } from '../data-provider/mu-nest/rest-provider';
import { OpenaiClientCreate, OpenaiClientEdit, OpenaiClientList, OpenaiClientShow } from '../openai';
import { DashboardList, DashboardEdit, DashboardCreate, DashboardShow } from '../mu-evaluation';
import { SurveyList, SurveyEdit, SurveyCreate, SurveyShow } from '../mu-humaneval'

const MomoryAdmin = () => {
  const [dataProvider, setDataProvider] = useState<DataProvider>();

  useEffect(() => {
    const environment = process.env.REACT_APP_ENVIRONMENT as string || 'local';
    const restProvider = restProviderFactory(environment);

    const clientOption = {
      client: apolloClient(),
      buildQuery: commonGQLQueryBuilder,
    } as Options;

    // Prod 환경일 경우 schema.json 불러와서 사용
    if (environment === 'prod') {
      const gqlSchema = Schema.__schema as unknown;
      clientOption.introspection = { schema: gqlSchema as IntrospectionSchema };
    }

    buildGraphQLProvider(clientOption)
      .then((gqlProvider) => {
        setDataProvider(
          combineDataProviders((resoruce) => {
            switch (resoruce) {
              case 'User':
                return gqlProvider;
              case 'Mory':
                return gqlProvider;
              case 'Report':
                return gqlProvider;
              case 'Gem':
                return gqlProvider;
              case 'StreakEvent':
                return gqlProvider;
              case 'MoryPass':
                return gqlProvider;
              case 'Announce':
                return gqlProvider;
              case 'Openai':
                return restProvider;
              default:
                throw new Error(`Unknown resource: ${resoruce}`);
            }
          }),
    );
      })
      .catch((error) => {
        console.log('build data provider', error);
      });
  }, []);

  return (
    <Admin loginPage={MyLoginPage} authProvider={AuthProvider} dataProvider={dataProvider} theme={MomoryTheme}>
      <Resource
        icon={Person}
        options={{ label: 'User' }}
        name="User"
        list={UserList}
        show={UserShow}
        create={UserCreate}
        edit={UserEdit}
      />
      <Resource icon={EmojiEmotions} options={{ label: 'Mory' }} name="Mory" list={MoryList} show={MoryShow} />
      <Resource
        icon={Report}
        options={{ label: 'Report' }}
        name="Report"
        list={ReportList}
        edit={ReportEdit}
        show={ReportShow}
      />
      <Resource icon={Diamond} options={{ label: 'Gem' }} name="Gem" list={GemList} />
      <Resource
        icon={EmojiEvents}
        options={{ label: 'Streak Event' }}
        name="StreakEvent"
        list={EventList}
        edit={EventEdit}
        create={EventCreate}
        show={EventShow}
      />
      <Resource
        icon={Redeem}
        options={{ label: 'Mory Pass' }}
        name="MoryPass"
        list={MoryPassList}
        show={MoryPassShow}
        edit={MoryPassEdit}
        create={MoryPassCreate}
      />
      <Resource
        icon={Campaign}
        options={{ label: 'Announce' }}
        name="Announce"
        list={AnnounceList}
        show={AnnounceShow}
        create={AnnounceCreate}
        edit={AnnounceEdit}
      />
      <Resource
        icon={Psychology}
        options={{ label: 'OpenAI' }}
        name="Openai"
        list={OpenaiClientList}
        show={OpenaiClientShow}
        create={OpenaiClientCreate}
        edit={OpenaiClientEdit}
      />
      <Resource 
        icon={Analytics}
        options={{ label: 'Mu Evaluation' }}
        name="dashboard" 
        list={DashboardList} 
        edit={DashboardEdit} 
        create={DashboardCreate} 
        show={DashboardShow} />
      <Resource 
        icon={Check}
        options={{ label: 'Mu Survey' }}
        name="survey" 
        list={SurveyList} 
        edit={SurveyEdit} 
        create={SurveyCreate} 
        show={SurveyShow} />
      <CustomRoutes>
        <Route path="/ban/:id/:userId/:targetType/:targetId/:parentId" element={<Ban />} />
      </CustomRoutes>
    </Admin>
  );
};

export default MomoryAdmin;
