import React, { useState } from 'react';
import {
  BooleanInput,
  Confirm,
  SaveButton,
  SelectInput,
  SimpleForm,
  TextInput,
  Toolbar,
  WrapperField,
  useDataProvider,
  useNotify,
} from 'react-admin';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

type BanForm = {
  userId?: string;
  restraintPeriod?: number;
  reason?: string;
  deleteContent?: boolean;
};

export const Ban = () => {
  const dp = useDataProvider();
  const [showBanConfirm, setShowBanConfirm] = useState(false);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  // const [deletePromise, setDeletePromise] = useState<Promise<any> | null>(null);
  const [formData, setFormData] = useState<BanForm>();
  const { id, userId, targetType, targetId, parentId } = useParams();

  const decordedId = decodeURIComponent(targetId || '');
  const decordedPID = decodeURIComponent(parentId || '');
  const notify = useNotify();
  const nav = useNavigate();

  const handleBanConfirm = () => {
    setShowBanConfirm(true);
  };
  const handleDeleteConfirm = () => {
    setShowDeleteConfirm(true);
  };

  const handleBanCancel = () => {
    setShowBanConfirm(false);
  };
  const handleDeleteCancel = () => {
    setShowDeleteConfirm(false);
  };

  const handleDeleteYes = () => {
    if (targetType === 'mory') {
      dp.delete('Mory', { id: targetId, meta: { input: { id: decordedId, userId } } }).then(() => {
        dp.getOne('Report', { id })
          .then((record) => {
            const resolvedReport = {
              ...record.data,
              status: 'resolved',
              resolvedAt: Date().toString(),
              resolvedBy: record.data.receivedBy,
            };
            dp.update('Report', {
              id: id || '',
              data: resolvedReport,
              previousData: record.data,
            });
          })
          .finally(() => {
            notify('Successfully delete user contents');
            nav(`/Report/${id}/show`);
          })
          .catch((err) => {
            console.log(err);
            notify(err, { type: 'error' });
          });
      });
    } else if (targetType === 'comment') {
      dp.delete('Comment', {
        id: targetId,
        meta: { input: { id: decordedId, userId, moryId: decordedPID, moryUserId: 'unknown' } },
      }).then(() => {
        dp.getOne('Report', { id })
          .then((record) => {
            const resolvedReport = {
              ...record.data,
              status: 'resolved',
              resolvedAt: Date().toString(),
              resolvedBy: record.data.receivedBy,
            };
            dp.update('Report', {
              id: id || '',
              data: resolvedReport,
              previousData: record.data,
            });
          })
          .finally(() => {
            notify('Successfully delete user contents');
            nav(`/Report/${id}/show`);
          })
          .catch((err) => {
            console.log(err);
            notify(err, { type: 'error' });
          });
      });
    }

    setShowBanConfirm(false);
  };
  const handleConfirmYes = () => {
    dp.create('Restraint', {
      data: { userId, reportId: id, restraintPeriod: formData?.restraintPeriod, reason: formData?.reason },
    })
      .then(() => {
        dp.getOne('Report', { id })
          .then((record) => {
            const resolvedReport = {
              ...record.data,
              status: 'resolved',
              resolvedAt: Date().toString(),
              resolvedBy: record.data.receivedBy,
            };
            dp.update('Report', {
              id: id || '',
              data: resolvedReport,
              previousData: record.data,
            });
          })
          .finally(() => {
            if (formData?.deleteContent) {
              if (targetType === 'mory') {
                dp.delete('Mory', { id: targetId, meta: { input: { id: decordedId, userId } } }).then(() => {
                  dp.getOne('Report', { id })
                    .then((record) => {
                      const resolvedReport = {
                        ...record.data,
                        status: 'resolved',
                        resolvedAt: Date().toString(),
                        resolvedBy: record.data.receivedBy,
                      };
                      dp.update('Report', {
                        id: id || '',
                        data: resolvedReport,
                        previousData: record.data,
                      });
                    })
                    .finally(() => {
                      notify('Successfully delete user contents');
                      nav(`/Report/${id}/show`);
                    })
                    .catch((err) => {
                      console.log(err);
                      notify(err, { type: 'error' });
                    });
                });
              } else if (targetType === 'comment') {
                dp.delete('Comment', {
                  id: targetId,
                  meta: { input: { id: decordedId, userId, moryId: decordedPID, moryUserId: 'unknown' } },
                }).then(() => {
                  dp.getOne('Report', { id })
                    .then((record) => {
                      const resolvedReport = {
                        ...record.data,
                        status: 'resolved',
                        resolvedAt: Date().toString(),
                        resolvedBy: record.data.receivedBy,
                      };
                      dp.update('Report', {
                        id: id || '',
                        data: resolvedReport,
                        previousData: record.data,
                      });
                    })
                    .finally(() => {
                      notify('Successfully delete user contents');
                      nav(`/Report/${id}/show`);
                    })
                    .catch((err) => {
                      console.log(err);
                      notify(err, { type: 'error' });
                    });
                });
              }
            }
            notify('Successfully delete user contents');
            nav(`/Report/${id}/show`);
          })
          .catch((err) => {
            console.log(err);
            notify(err, { type: 'error' });
          });
        setShowBanConfirm(false);
      })
      .catch((error) => {
        console.error(error);
        notify('Error: User ban failed');
      });
    setShowBanConfirm(false);
  };

  const BanToolbar = () => {
    const confirmationBan = `Are you sure you want to ban "${userId}" \n for "${formData?.restraintPeriod}" days because of ${formData?.reason}" reason?`;
    const confirmationDeleteContent = `Are you sure you want to delete "${targetType?.toLocaleUpperCase()}" ID "${targetId}"?`;

    return (
      <Toolbar>
        <SaveButton label="Ban User" onClick={handleBanConfirm} type="button" variant="text" />
        <SaveButton label="Delete Content" onClick={handleDeleteConfirm} type="button" variant="text" alwaysEnable />
        <Confirm
          title="Banning User"
          isOpen={showBanConfirm}
          content={confirmationBan}
          onConfirm={handleConfirmYes}
          onClose={handleBanCancel}
        />
        <Confirm
          title="Delete Content"
          isOpen={showDeleteConfirm}
          content={confirmationDeleteContent}
          onConfirm={handleDeleteYes}
          onClose={handleDeleteCancel}
        />
      </Toolbar>
    );
  };

  return (
    <>
      <WrapperField record={{ userId, id }}>
        <SimpleForm toolbar={<BanToolbar />}>
          <TextInput label="userId" source="userId" defaultValue={userId} disabled />
          <SelectInput
            source="restraintPeriod"
            choices={[
              { id: 1, name: '1 day' },
              { id: 3, name: '3 days' },
              { id: 7, name: '7 days' },
              { id: 14, name: '14 days' },
              { id: 30, name: '30 days' },
              { id: 100, name: '100 days' },
              { id: 36500, name: 'permanent' },
            ]}
            onChange={(event) => setFormData({ ...formData, restraintPeriod: event.target.value })}
          />
          <TextInput
            source="reason"
            helperText="정지 사유"
            onChange={(event) => setFormData({ ...formData, reason: event.target.value })}
          />
          <BooleanInput
            source="deleteContent"
            label="Delete Contents"
            onChange={(event) => setFormData({ ...formData, deleteContent: event.target.checked })}
          />
        </SimpleForm>
      </WrapperField>
    </>
  );
};
