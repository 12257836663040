import { Edit, SelectInput, SimpleForm, TextField, TextInput } from 'react-admin';

export const OpenaiClientEdit = () => (
  <Edit>
    <SimpleForm>
      <TextField source="id" />
      <SelectInput source="provider" choices={[
        { id: 'openai', name: 'OpenAI' },
        { id: 'azure', name: 'Azure' },
      ]} />
      <TextInput source="clientName" name='client_name' label='Name'/>
      <TextInput source="apiKey" name='api_key' label='Api Key'/>
      <TextInput source="endpoint" />
    </SimpleForm>
  </Edit>
);
