import {
  ChipField,
  Datagrid,
  DateField,
  FunctionField,
  List,
  NumberField,
  ReferenceField,
  SelectInput,
  TextField,
  TextInput,
} from 'react-admin';
import { EmotionTag } from '../common/components/EmotionTag';

const moryFilters = [
  <TextInput label="ID" source="id" />,
  <TextInput label="User ID" source="userId" />,
  <SelectInput
    label="isPublic"
    source="isPublic"
    defaultValue="true"
    choices={[
      { id: 'true', name: 'true' },
      { id: 'false', name: 'false' },
    ]}
  />,
];

export const MoryList = () => {
  return (
    <List filters={moryFilters}>
      <Datagrid
        rowClick="show"
        sx={{
          '& .column-id': { width: 100 },
          '& .column-userId': { width: 100 },
          '& .column-createdAt': { width: 100 },
          '& .column-commentCount': { width: 100 },
          '& .column-viewCount': { width: 100 },
        }}
      >
        <DateField source="createdAt" label="생성일" showTime />
        <TextField
          source="text"
          label="내용"
          sx={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            display: '-webkit-box',
            WebkitLineClamp: '2',
            WebkitBoxOrient: 'vertical',
          }}
        />
        <FunctionField
          label="감정"
          render={(record: any) => {
            return <EmotionTag emotion={record.emotion} />;
          }}
        />

        <NumberField source="commentCount" label="댓글" />
        <NumberField source="viewCount" label="조회" />
        <FunctionField
          label="House"
          render={(record: any) => {
            if (record.houses?.length) {
              return (
                <div>
                  {record.houses.map((e: any) => {
                    return <ChipField key={e.id} source="housename" record={{ housename: `${e.icon} ${e.name}` }} />;
                  })}
                </div>
              );
            } else {
              return <></>;
            }
          }}
        />
        <TextField source="id" label="Mory ID" />
        <ReferenceField
          source="userId"
          reference="User"
          label="유저ID"
          sx={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            display: '-webkit-box',
            WebkitLineClamp: '2',
            WebkitBoxOrient: 'vertical',
          }}
        />
      </Datagrid>
    </List>
  );
};
