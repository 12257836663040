import { indigo, pink, red } from '@mui/material/colors';
import { defaultTheme } from 'react-admin';

export const MomoryTheme = {
  ...defaultTheme,
  components: {
    ...defaultTheme.components,
    RaAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: 'whitesmoke',
          color: 'black',
        },
      },
    },
    MuiChip: {
      defaultProps: {
        variant: 'outlined',
      },
    },
  },
  palette: {
    primary: indigo,
    secondary: pink,
    error: red,
    contrastThreshold: 3,
    tonalOffset: 0.2,
  },
  typography: {
    fontFamily: ['-apple-system', 'BlinkMacSystemFont', '"Segoe UI"', 'Arial', 'sans-serif'].join(','),
  },
  sidebar: {
    width: 200, // The default value is 240
    closedWidth: 50, // The default value is 55
  },
};
