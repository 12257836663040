import {
  DateField,
  FunctionField,
  NumberField,
  Pagination,
  ReferenceManyField,
  RichTextField,
  Show,
  TextField,
  useGetRecordId,
  useRecordContext,
  WithListContext,
  SimpleShowLayout,
  Labeled,
  ChipField,
  ReferenceField,
} from 'react-admin';
import { Map, Marker } from 'pigeon-maps';
import Grid from '@mui/material/Unstable_Grid2';
import { Divider, List, ListItem } from '@mui/material';
import { S3Image } from '../common/components/S3Image';
import { EmotionTag } from '../common/components/EmotionTag';

const CommentField = (uid: string, content: string, writer = false) => {
  return (
    <div>
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <div style={{ fontSize: '0.7rem' }}>{uid}</div>
      </div>
      <TextField source="content" record={{ content }} label={uid} />
    </div>
  );
};

const SubcommentField = (uid: string, content: string) => {
  return (
    <div style={{ display: 'flex', flexDirection: 'row' }}>
      <div style={{ width: '2rem' }}>↳</div>
      <div>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <div style={{ fontSize: '0.7rem' }}>{uid}</div>
        </div>
        <TextField source="content" record={{ content }} label={uid} />
      </div>
    </div>
  );
};

const GeoMap = () => {
  const record = useRecordContext();
  if (record) {
    const lat = record?.latitude;
    const lnt = record?.longitude;
    return (
      <Map height={200} width={200} defaultCenter={[lat, lnt]} defaultZoom={15}>
        <Marker width={30} anchor={[lat, lnt]} />
      </Map>
    );
  } else {
    return <div>No Geo Infomation</div>;
  }
};

export const MoryShow = () => {
  const userReportFilter = {
    targetSubId: useGetRecordId(),
  };

  const userCommentFilter = {
    moryId: useGetRecordId(),
  };

  return (
    <Show emptyWhileLoading>
      <List>
        <ListItem>
          <Grid lg sx={{ display: 'grid', gridArea: 'header' }}>
            <TextField sx={{ fontWeight: 'bold', fontSize: 32 }} label={false} source="id" />
            <DateField source="createdAt" label={false} showTime />
          </Grid>
        </ListItem>
        <Divider />
        <ListItem>
          <Grid
            lg
            sx={{
              gridArea: 'info',
              display: 'grid',
              gridTemplateColumns: '200px 300px 1fr',
              gap: 1,
              gridTemplateRows: 'auto',
              gridTemplateAreas: `"uid deleted image"
          "text text image"
          "customlabel emotionname image"
          "emotion house image"
          "location viewcount image"
          "location report image"
          `,
            }}
          >
            <Labeled label="유저 Id" sx={{ gridArea: 'uid' }}>
              <ReferenceField reference="User" link="show" source="userId" />
            </Labeled>
            <Labeled label="조회수" sx={{ gridArea: 'viewcount' }}>
              <NumberField source="viewCount" />
            </Labeled>
            <Labeled label="감정" sx={{ gridArea: 'emotion' }}>
              <FunctionField
                render={(record: any) => {
                  if (record.emotionDetail) {
                    return (
                      <div>
                        {record.emotionDetail.map((e: any, idx: number) => (
                          <EmotionTag emotion={e.emotions} degree={e.degrees} key={idx} />
                        ))}
                      </div>
                    );
                  } else {
                    return <EmotionTag emotion={record.emotion} />;
                  }
                }}
              />
            </Labeled>
            <Labeled label="감정 이름" sx={{ gridArea: 'emotionname' }}>
              <TextField source="nameOfEmotion" emptyText="N/A" />
            </Labeled>
            <Labeled label="Custom Label" sx={{ gridArea: 'customlabel' }}>
              <FunctionField
                render={(record: any) =>
                  !record.nameOfEmotion || record.nameOfEmotion === '' ? <>N/A</> : <TextField source="nameOfEmotion" />
                }
              />
            </Labeled>
            <Labeled label="House" sx={{ gridArea: 'house' }}>
              <FunctionField
                render={(record: any) => {
                  if (record.houses?.length) {
                    return (
                      <div>
                        {record.houses.map((e: any) => {
                          return (
                            <ChipField key={e.id} source="housename" record={{ housename: `${e.icon} ${e.name}` }} />
                          );
                        })}
                      </div>
                    );
                  } else {
                    return <>N/A</>;
                  }
                }}
              />
            </Labeled>
            <Labeled label="내용" sx={{ gridArea: 'text' }}>
              <RichTextField source="text" emptyText="N/A" />
            </Labeled>
            <Labeled label="위치" sx={{ gridArea: 'location' }}>
              <GeoMap />
            </Labeled>
            <Labeled label="사진" sx={{ gridArea: 'image' }}>
              <S3Image source="imageKey" height={400} width={400} />
            </Labeled>
            <Labeled label="신고 내역" sx={{ gridArea: 'report' }}>
              <ReferenceManyField reference="Report" target="targetSubId" filter={userReportFilter}>
                <WithListContext
                  render={({ data }) => {
                    if (!data || data.length === 0) return <div style={{ fontSize: '14px' }}>N/A</div>;
                    return (
                      <div>
                        {data.map((report: any, idx: number) => {
                          return (
                            <div key={report.id}>
                              <ReferenceField
                                reference="Report"
                                link="show"
                                source="reportId"
                                record={{ reportId: report.id }}
                              >
                                <Labeled label={report.createdAt}>
                                  <TextField source="content" record={{ content: report.type }} />
                                </Labeled>
                              </ReferenceField>
                            </div>
                          );
                        })}
                      </div>
                    );
                  }}
                />
              </ReferenceManyField>
            </Labeled>
          </Grid>
        </ListItem>
        <Divider />
        <ListItem>
          <Grid gridArea="comment">
            <Labeled label="댓글">
              <ReferenceManyField
                pagination={<Pagination />}
                reference="Comment"
                target="moryId"
                filter={userCommentFilter}
              >
                <WithListContext
                  render={({ data }) => {
                    if (!data || data.length === 0)
                      return (
                        <div key={'key'} style={{ padding: '16px' }}>
                          <div style={{ margin: '0px', fontSize: '14px' }}>No comments found</div>
                        </div>
                      );
                    return (
                      <SimpleShowLayout>
                        {data.map((comment: any, idx: number) => {
                          return (
                            <div key={comment.id}>
                              <div>{CommentField(comment.userId, comment.content)}</div>
                              {comment.subcomment ? (
                                comment.subcomment.map((subcomment: any) => (
                                  <div key={subcomment.id}>
                                    {SubcommentField(subcomment.userId, subcomment.content)}
                                  </div>
                                ))
                              ) : (
                                <div key={idx}></div>
                              )}
                            </div>
                          );
                        })}
                      </SimpleShowLayout>
                    );
                  }}
                />
              </ReferenceManyField>
            </Labeled>
          </Grid>
        </ListItem>
      </List>
    </Show>
  );
};
