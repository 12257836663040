import simplerestProvider from 'ra-data-simple-rest';
import { DataProvider } from 'react-admin';

const endpointList: { [key: string]: string } = {
  local: 'http://localhost:8000',
  dev: 'https://mu-nest-dev.azurewebsites.net',
  prod: 'https://mu-nest-prod.azurewebsites.net',
};

export function restProviderFactory(environment: string = 'local'): DataProvider {
  return simplerestProvider(endpointList[environment]);
}
