import React, { useState } from 'react';
import { Card, CardContent, Typography, Button, Box, TextField, Grid } from '@mui/material';
import { fetchData } from '../data-provider/mongodb/mongodb-provider';

interface DataEntry {
  _id: string;
  turn_id: string;
  user_input: string;
  thought_prompt: string;
  thought_output: string;
  action_tag_prompt: string;
  action_tag_output: string;
  memories: string;
  topic_classifier_prompt: string;
  topic_classifier_output: string;
  reflections: string;
  note_taker_prompt: string;
  note_taker_output: string;
  talk_prompt: string;
  talk_output: string;
}

export const SurveyList: React.FC = () => {
  const [userId, setUserId] = useState<string>('');
  const [selectedData, setSelectedData] = useState<DataEntry | null>(null);

  const handleRandomSample = async () => {
    const today = new Date();
    const startDate = new Date();
    startDate.setDate(today.getDate() - 10);
    const data = await fetchData(startDate.toISOString(), today.toISOString());
    const randomSample = data[Math.floor(Math.random() * data.length)] as DataEntry;
    setSelectedData(randomSample);
  };

  const handleSurvey = () => {
    if (userId && selectedData) {
      const surveyLink = `https://xp63e8alcff.typeform.com/to/q5xOhbyg#dialog_id=${selectedData.turn_id}&user_id=${userId}`;
      window.open(surveyLink, '_blank');
    } else {
      alert('Please enter your own User ID.');
    }
  };

  const renderFieldPair = (label: string, prompt: string, output: string) => (
    <Box mb={2} p={2} border={1} borderRadius={4}>
      <Typography variant="h6">{label}</Typography>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Box p={1} border={1} borderRadius={4} style={{ maxHeight: 200, overflow: 'auto' }}>
            <Typography variant="body1" style={{ whiteSpace: 'pre-line' }}><strong>Prompt:</strong> {prompt}</Typography>
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box p={1} border={1} borderRadius={4} style={{ maxHeight: 200, overflow: 'auto' }}>
            <Typography variant="body1" style={{ whiteSpace: 'pre-line' }}><strong>Output:</strong> {output}</Typography>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );

  const renderField = (label: string, value: string) => (
    <Box mb={2} p={2} border={1} borderRadius={4}>
      <Typography variant="body1" style={{ whiteSpace: 'pre-line' }}><strong>{label}:</strong> {value}</Typography>
    </Box>
  );

  return (
    <Card>
      <CardContent>
        <Typography variant="h5">Human Evaluation Dashboard</Typography>
        <Box mt={2} display="flex" flexDirection="column" gap={2}>
          <Box display="flex" flexDirection="row" alignItems="center" gap={2}>
            <Button variant="contained" color="primary" onClick={handleRandomSample}>
              Show Random Sample
            </Button>
            <TextField
              label="User ID"
              value={userId}
              onChange={(e) => setUserId(e.target.value)}
              fullWidth
            />
            <Button variant="contained" color="primary" onClick={handleSurvey}>
              Go to Survey
            </Button>
          </Box>
          {selectedData && (
            <Box>
              {renderField('Dialog ID', selectedData._id)}
              {renderField('Turn ID', selectedData.turn_id)}
              {renderField('User Input', selectedData.user_input)}
              {renderFieldPair('Thought', selectedData.thought_prompt, selectedData.thought_output)}
              {renderFieldPair('Action Tag', selectedData.action_tag_prompt, selectedData.action_tag_output)}
              {renderField('Memories', selectedData.memories)}
              {renderField('Reflections', selectedData.reflections)}
              {renderFieldPair('Topic Classifier', selectedData.topic_classifier_prompt, selectedData.topic_classifier_output)}
              {renderFieldPair('Note Taker', selectedData.note_taker_prompt, selectedData.note_taker_output)}
              {renderFieldPair('Talk', selectedData.talk_prompt, selectedData.talk_output)}
            </Box>
          )}
        </Box>
      </CardContent>
    </Card>
  );
};

export default SurveyList;