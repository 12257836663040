import { Datagrid, List, TextField } from 'react-admin';

export const OpenaiClientList = () => (
  <List>
    <Datagrid rowClick="show">
      <TextField source="client_name" />
      <TextField source="provider" />
      <TextField source="api_key" />
      <TextField source="endpoint" />
      <TextField source="_id" />
    </Datagrid>
  </List>
);
