import { useState } from 'react';
import { fetchData } from '../data-provider/mongodb/mongodb-provider'
import { Card, CardContent, Typography, Button, Box, Grid } from '@mui/material';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import BarPlot from './plots/barplot';
import LinePlot from './plots/lineplot';
import StackedBarPlot from './plots/stackedbarplot';
import { eachDayOfInterval, format } from 'date-fns';

// Helper function to find last id
const findLastId = (text: string) => {
    const target = "Below is the last updated therapy note, id number";
    const id_pos = text.indexOf(target) + target.length + 1;
    const val = text.substring(id_pos, id_pos + 3).replace(".", "");
    return parseInt(val, 10);
};

export const DashboardList = () => {
    const [startDate, setStartDate] = useState<Date | null>(null);
    const [endDate, setEndDate] = useState<Date | null>(null);
    const [showPlots, setShowPlots] = useState(true);
    const [xData, setXData] = useState<string[]>([]);
    const [conversationCount, setConversationCount] = useState<number[]>([]);
    const [questionRatio, setQuestionRatio] = useState<number[]>([]);
    const [topicChangeRatio, setTopicChangeRatio] = useState<number[]>([]);
    const [conversationSpeed, setConversationSpeed] = useState<number[]>([]);
    const [counselorData, setCounselorData] = useState<{ name: string; y: number[] }[]>([]);

    const handleSubmit = async () => {
        if (startDate && endDate) {
            const formattedStartDate = startDate.toISOString();
            const formattedEndDate = endDate.toISOString();
            const data = await fetchData(formattedStartDate, formattedEndDate);
            const daysArray = eachDayOfInterval({ start: startDate, end: endDate });
            const formattedDays = daysArray.map((day) => format(day, 'yyyy-MM-dd'));
            setXData(formattedDays);

            // Process the data
            const df = data.map((item: { thought_start: Date; talk_output: string; action_tag_output: string; talk_end: Date; topic_classifier_output: string; topic_classifier_prompt: string; }) => ({
                ...item,
                date: new Date(item.thought_start).toISOString().split('T')[0],
                question: item.talk_output.includes("?"),
                action_tags: item.action_tag_output.replace("Tags: ", "").split(", "),
                time_spent: (new Date(item.talk_end).getTime() - new Date(item.thought_start).getTime()) / 1000,
                current_id: item.topic_classifier_output? JSON.parse(item.topic_classifier_output).id : null,
                current_action: item.topic_classifier_output ? JSON.parse(item.topic_classifier_output).action : null,
                last_id: findLastId(item.topic_classifier_prompt)
            }));
            
            df.forEach((item: { current_id: any; last_id: any; topic_changed: boolean; current_action: string; }) => {
                item.current_id = item.current_id ?? item.last_id;
                item.topic_changed = item.current_action === 'create' || item.current_id !== item.last_id;
            });

            interface DataMap {
                [key: string]: number;
            }
            
            interface CounselorBehaviorMap {
                [key: string]: { [key: string]: number };
            }
            
            const conversationCountMap: DataMap = {};
            const questionRatioMap: DataMap = {};
            const topicChangeRatioMap: DataMap = {};
            const conversationSpeedMap: DataMap = {};
            const counselorBehaviorMap: CounselorBehaviorMap = {};

            formattedDays.forEach(date => {
                const dailyData = df.filter((item: { date: string; }) => item.date === date);
                conversationCountMap[date] = dailyData.length;
                questionRatioMap[date] = dailyData.filter((item: { question: boolean; }) => item.question).length / dailyData.length || 0;
                topicChangeRatioMap[date] = dailyData.filter((item: { topic_changed: boolean; }) => item.topic_changed).length / dailyData.length || 0;
                conversationSpeedMap[date] = dailyData.reduce((acc: any, item: { time_spent: any; }) => acc + item.time_spent, 0) / dailyData.length || 0;
                dailyData.forEach((item: { action_tags: string[]; }) => {
                    item.action_tags.forEach((tag: string) => {
                        if (!counselorBehaviorMap[date]) counselorBehaviorMap[date] = {};
                        counselorBehaviorMap[date][tag] = (counselorBehaviorMap[date][tag] || 0) + 1;
                    });
                });
            });
            setConversationCount(formattedDays.map(date => conversationCountMap[date] || 0));
            setQuestionRatio(formattedDays.map(date => questionRatioMap[date] || 0));
            setTopicChangeRatio(formattedDays.map(date => topicChangeRatioMap[date] || 0));
            setConversationSpeed(formattedDays.map(date => conversationSpeedMap[date] || 0));
            // Ensure the tags are present in all formattedDays
            const allTags = Object.keys(counselorBehaviorMap).reduce((acc: string[], date) => {
                Object.keys(counselorBehaviorMap[date]).forEach(tag => {
                    if (!acc.includes(tag)) {
                        acc.push(tag);
                    }
                });
                return acc;
            }, []);

            const counselorBehaviorSeries: { name: string; y: number[] }[] = [];
            allTags.forEach(tag => {
                counselorBehaviorSeries.push({
                    name: tag,
                    y: formattedDays.map(date => counselorBehaviorMap[date] ? counselorBehaviorMap[date][tag] || 0 : 0)
                });
            });
            setCounselorData(counselorBehaviorSeries);
        }
    };

    return (
        <Card>
            <CardContent>
                <Typography variant="h5">Dashboard</Typography>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <Box display="flex" flexDirection="row" gap={2} alignItems="center">
                        <DatePicker
                            label="Start Date"
                            value={startDate}
                            onChange={(newValue) => setStartDate(newValue)}
                        />
                        <DatePicker
                            label="End Date"
                            value={endDate}
                            onChange={(newValue) => setEndDate(newValue)}
                        />
                        <Button variant="contained" color="primary" onClick={handleSubmit}>
                            Submit
                        </Button>
                    </Box>
                </LocalizationProvider>
                <Box mt={2} display="flex" gap={2}>
                    <Button onClick={() => setShowPlots(!showPlots)}>{showPlots ? 'Hide' : 'Show'} Plots</Button>
                </Box>
                <Grid container spacing={2} mt={2}>
                    {showPlots && (
                        <>
                            <Grid item xs={12} md={6}>
                                <BarPlot xData={xData} yData={conversationCount} title={'Total Conversations'} />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <LinePlot xData={xData} yData={questionRatio} title={'Question Ratio in Conversations'} />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <LinePlot xData={xData} yData={topicChangeRatio} title={'Topic Change Ratio in Conversations'} />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <LinePlot xData={xData} yData={conversationSpeed} title={'Average Conversation Speed'} />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <StackedBarPlot xData={xData} yData={counselorData} title={'Counselor Behavior'} />
                            </Grid>
                        </>
                    )}
                </Grid>
            </CardContent>
        </Card>
    );
};